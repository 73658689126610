import React from "react";
import "../components/chapter.css";
import "../App.css";


function C1text() {
  return (
    <div>
      <h1>The Book Of XXisp</h1>
      <h2>Chapter 1</h2>
      <div className="container">
        <p>“X-isp?….kcsskccsipp?”</p>

        <p>
          “It's Crisp, It’s pronounced Crisp…” he quickly whipped back, snapping
          out of a daydream upon hearing his name.
        </p>

        <p>
          “Thats not what it says here, it’s a letter addressed, to XXisp. X X I
          S..”
        </p>

        <p>
          “That’s my name, I am XXisp”. He was blunt and abrupt at the best of
          times, and this was not the best of times.
        </p>

        <p>
          “Oh I am terribly sorry, Lovely, just that theres no surname either,
          I’ve never seen that name before” replied the Nurse as she quickly
          tried to rectify her misunderstanding as politely as possible.
        </p>

        <p>
          Too late, she was about to be as they say “in the wrong place at the
          wrong time”.
        </p>

        <p>
          “I DON’T HAVE A SURNAME. IT IS A VERY CLEARLY AND WELL ADDRESSED
          LETTER.”.
        </p>

        <p>
          Poor Nurse Joy was about to get both barrels of a very tried, angry
          and confused man.
        </p>

        <p>
          “HOW DARE YOU TELL ME WHAT MY NAME IS AND ISN’T”. He was up out of his
          chair now, as he slowly started to gain focus back after falling out
          of a deep medication-induced daydream. Scuttling about he started
          pacing the room looking for something as the words were trying to
          leave his mouth, like he was trying to shout but he needed to find
          what it was he was searching for first.
        </p>

        <p>
          Before anyone else had moved, XXisp has reached over the ping-pong
          table, grabbed a scrunched up piece of paper and a pen out of his
          pocket. “WHAT DOES THAT SAY?? GO ON TELL ME?” XXisp had drawn about 5
          unintelligible squiggly lines on the paper and was shoving it in the
          Nurse’s face.
        </p>

        <p>
          The longer he had to come to his senses and take note of where he was,
          the more irate he would get. Poor Nurse Joy had lit the blue taper, he
          was off!!
        </p>

        <p>
          “I WILL TELL YOU WHAT IT MEANS, IT MEANS YOU’RE FUCKING STUPID AND
          IT’S IN A LANGUAGE CALLED YOU’RE A STUPID SLAG!!”
        </p>

        <p>
          Nurse Joy was literally speechless, she was frozen in shock. She had
          only met XXisp once or twice before passing on the ward and giving out
          meds. He was off though, like a rocket.
        </p>

        <p>
          “IF YOU INTRODUCE YOURSELF TO ME AS NURSE JOY, I WON'T SAY TO YOU, NO
          YOU’RE FUCKING NOT. IF I SAY TO …” whack!!
        </p>

        <p>
          Like flash bang going off, ringing in his ears, he didn't know if up
          was down or down was up, the sound of alarms…something just happened.
          He just didn't know what.
        </p>

        <p>
          Nurse Joy was cowering with tears streaming down her face in the
          corner, shaking and terrified she realized she was ok…she wasn't hurt.
          As she peered her head, flinching still as she looked past her arms
          that were protecting her face, she could see XXisp.
        </p>

        <p>
          Three of the Male staff had taken him down and they were doing their
          very best to restrain him…when I say their best…it was not the best.
          Oh yeah, did I not tell you? XXisp had 3 fights in the UFC and about
          20 professional MMA fights.
        </p>

        <p>
          The whole place had been on pins waiting for this. All the staff knew
          him, what he did and he was also a very tall and strong muscular man.
        </p>

        <p>
          “Lorenzo, go back to your room please, mate”. Henry, A fresh-faced
          19-year-old nursing student sheepishly pleaded, clearly not akin to
          the hustle and bustle of an acute mental health ward full of
          schizophrenia, psychosis, and all the other volatile aspects of a
          psychiatric ward.
        </p>

        <p>
          “He’s me mate, I wanna see if hes OK. He doesn't look OK, looks like
          they're tryna bum his head in lad”.
        </p>

        <p>
          The look on the new nursing student’s face could tell a tale,
          absolutely brand new, out of towner, never seen city life. Truly what
          you would describe as a wet limp nerd.
        </p>

        <p>
          He was a pediatric student nurse on placement, this was not his world.
          He had never seen rush and adrenaline like this…in that instant that
          young lad had so many “norms” broken right in front of him. Shown a
          world he didn't have any want or need for.
        </p>

        <p>
          He took a big gulp. “Aye, come and tell me about that theory you were
          talking about before”.
        </p>

        <p>
          “Oh shit… Cant even believe that worked, I think I seen that on
          spongebob” Henry narrated to himself internally as he grew in
          confidence, if only for a second.
        </p>

        <p>There was still ALL OUT CHAOS going on externally.</p>

        <p>
          “Well it’s not really a theory, I was just saying, dya reckon if men
          could have babies we would all be gay or…”
        </p>

        <p>
          Henry knew, there was only one thing that could distract Lorenzo from
          the all-out mayhem down the corridor. He was the king of the
          conspiracy nuts. Well, he thought he was anyway. You couldn't be on
          the ward for 5 minutes before Lorenzo was asking you “did you know
          that cranes aren’t real?”
        </p>

        <p>
          Outside XXisp had been getting restrained for at least 4 minutes now.
          The remaining staff ushered the other patients to their rooms with the
          promise of extra ciggies and biscuits.
        </p>

        <p>
          Nurse Joy had been taken to the staff room by the ward manager, she
          was a nervous wreck as she held a sweet tea with two white bloodless
          hands. She was like a ghost, almost transparent.
        </p>

        <p>
          Bare in mind these guys had a free shot on him, from behind, they were
          still all over the place, XXisp knows how to fight and stop people
          hurting him, but when there's a man on 3/4 limbs and 3 more running
          down the hallway, there was nothing left but to surrender, they had
          him.
        </p>

        <p>“I AM FUCKING CALM”</p>

        <p>He was not.</p>

        <p>
          “Listen XXisp…It’s me, Dave” he liked Dave, Dave always wore band
          t-shirts. That was the first thing they spoke about, Dave had an old
          school “The Offspring” shirt the day he came in.
        </p>

        <p>“JUST FUCKING GET OFF ME THEN AND I'LL CALM DOWN”</p>

        <p>
          XXisp wasn't exactly an Offspring fan, he was a 6ft 3 black man with
          charms in his dreads and a dark blue tie-dye khaftan hoody…not his
          vibe. He just loved music. More than anything.
        </p>

        <p>“Mate, we can't le…”</p>

        <p>
          “I AM NOT YOUR FUCKING MATE” XXisp screamed into the floor as he lay
          facedown with a set of Levi jean-covered thighs nestled against his
          head. There's no way these restraint techniques are normal, regular,
          or certified by the hospital, but neither was XXisp.
        </p>

        <p>
          As quick as he interrupted, Dave gave the nod to a male Nurse
          juxtaposed to him, and just like that… He felt a little scratch in his
          arse and fell straight to sleep.
        </p>

        <p>
          A long eerie creak with a loud cushioned slam.
          Uuuuuuuurrrrrgghh…thump.
        </p>

        <p>
          XXisp sat up, startled again, just like when he was daydreaming,
          except this time he was even more confused.
        </p>

        <p>
          “What the fuck has just happened”. He knew where he was, as in he was
          in the hospital. He knew he had been here for about 20 days and why he
          was here. What he couldn't make sense of is why he was in his room…in
          pajamas.
        </p>

        <p>
          Sitting up and placing his feet on the cold rough non-slip floor, he
          scanned the room. ‘Nothing weird, nothing out of place…I just did not
          go to sleep here’ was his analysis.
        </p>

        <p>
          He placed his slippers on, got up, put on his jacket and made his way
          to his little vanity table. He couldn't figure out why they called it
          a vanity table, there was no mirror. It was just a chair and a desk.
        </p>

        <p>
          On the desk there lay a small white envelope. It was about the size
          and weight of a deck of cards. A small feint outline of a circle in
          the center, there was clearly something inside. Slowly and carefully
          as to not rip the envelope too much, XXisp peeled apart the sticky,
          still moist flap and slid out a small glowing translucent disk.
        </p>

        <p>
          It was mostly clear, like glass, with copper wires and strange small
          gems inside…like a coin, but like…not like a coin.
        </p>

        <p>
          Flipping the envelope over there was a small scribble on the top
          corner of the envelope
        </p>

        <p>“FOR XXISP, FROM LORENZO.”</p>
      </div>
    </div>
  );
}

export default C1text;
